


import Icon from "@mui/material/Icon";
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

import { lazy } from 'react';

const Dashboard = lazy(() => import('layouts/dashboard'));
const Monitors = lazy(() => import('layouts/monitor'));
const DNS = lazy(() => import('layouts/dns'));
const AI = lazy(() => import('layouts/gpt'));
const Profile = lazy(() => import('layouts/profile'));
const SignIn = lazy(() => import('layouts/authentication/sign-in'));
const FirmenDB = lazy(() => import('layouts/firmendb'));

const Temp_Dashboard = lazy(() => import('layouts/template/dashboard'));
const Temp_Billing = lazy(() => import('layouts/template/billing'));
const Temp_Tables = lazy(() => import('layouts/template/tables'));
const Temp_Notifications = lazy(() => import('layouts/template/notifications'));
const Temp_Profile = lazy(() => import('layouts/template/profile'));
const Temp_SignIn = lazy(() => import('layouts/template/authentication/sign-in'));
const Temp_SignUp = lazy(() => import('layouts/template/authentication/sign-up'));




const getRoutes = () => {
  const myString = window.location.pathname;
  const splitArray = myString.split(/[:=]/);
  const templateValue = splitArray[1];

  let TemplateVisibility;

  if (templateValue === "template") {
    TemplateVisibility = true;
  } else {
    TemplateVisibility = false;
  }

  const isAuthenticated = () => {
  // Get token from cookie
  //const token = Cookies.get('jwtToken');
  // Get token from local
  const token = localStorage.getItem('jwtToken');
    if (!token) return false;
  
    try {
      const decodedToken = jwt_decode(token);
      const currentTime = Date.now() / 1000; // convert to Unix timestamp
      return decodedToken.exp > currentTime;
    } catch (error) {
      return false;
    }
  };
  

  const routes = [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: TemplateVisibility ? ("/dashboard:template"):("/dashboard"),
      component: <Dashboard />,
    },
    {
      type: "collapse",
      name: "Monitor",
      key: "monitor",
      icon: <Icon fontSize="small">wifi</Icon>,
      route: TemplateVisibility ? ("/monitor:template"):("/monitor"),
      component: <Monitors />,
    },
    {
      type: "collapse",
      name: "Dns",
      key: "dns",
      icon: <Icon fontSize="small">dns</Icon>,
      route: TemplateVisibility ? ("/dns:template"):("/dns"),
      component: <DNS />,
    },
    {
      type: "collapse",
      name: "AI",
      key: "ai",
      icon: <Icon fontSize="small">article</Icon>,
      route: TemplateVisibility ? ("/ai:template"):("/ai"),
      component: <AI />,
    },
    {
      type: "collapse",
      name: "FirmenDB",
      key: "firmendb",
      icon: <Icon fontSize="small">article</Icon>,
      route: TemplateVisibility ? ("/firmendb:template"):("/firmendb"),
      component: <FirmenDB />,
    },
    isAuthenticated() ? (
      {
        type: "collapse",
        name: "Profile",
        key: "profile",
        icon: <Icon fontSize="small">person</Icon>,
        route: TemplateVisibility ? ("/profile:template"):("/profile"),
        component: <Profile />,
      }) : (
      {
        type: "collapse",
        name: "Sign In",
        key: "sign-in",
        icon: <Icon fontSize="small">login</Icon>,
        route: "/authentication/sign-in",
        component: <SignIn />,
      }),
    TemplateVisibility && {
      type: "collapse",
      name: "Template Dashboard",
      key: "dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/temp-dashboard:template",
      component: <Temp_Dashboard />,
    },
    TemplateVisibility && {
      type: "collapse",
      name: "Template Tables",
      key: "tables",
      icon: <Icon fontSize="small">table_view</Icon>,
      route: "/temp-tables:template",
      component: <Temp_Tables />,
    },
    TemplateVisibility && {
      type: "collapse",
      name: "Template Billing",
      key: "billing",
      icon: <Icon fontSize="small">receipt_long</Icon>,
      route: "/temp-billing:template",
      component: <Temp_Billing />,
    },
    TemplateVisibility && {
      type: "collapse",
      name: "Template Notifications",
      key: "notifications",
      icon: <Icon fontSize="small">notifications</Icon>,
      route: "/temp-notifications:template",
      component: <Temp_Notifications />,
    },
    TemplateVisibility && {
      type: "collapse",
      name: "Template Profile",
      key: "profile",
      icon: <Icon fontSize="small">person</Icon>,
      route: "/temp-profile:template",
      component: <Temp_Profile />,
    },
    TemplateVisibility && {
      type: "collapse",
      name: "TemplateSign Up",
      key: "sign-up",
      icon: <Icon fontSize="small">assignment</Icon>,
      route: "/temp-authentication/sign-up:template",
      component: <Temp_SignUp />,
    },
    TemplateVisibility && {
      type: "collapse",
      name: "Template Sign In",
      key: "temp-sign-in",
      icon: <Icon fontSize="small">login</Icon>,
      route: "/temp-authentication/sign-in:template",
      component: <Temp_SignIn />,
    },
  ];

  return routes;
  };
  
  export default getRoutes();