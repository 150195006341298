/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
import { useState, useEffect, useMemo } from "react";

import { Suspense } from 'react';

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";


// Material Dashboard 2 React routes
import routes from "routes";
import SignIn from "layouts/authentication/sign-in";
import Cookies from 'js-cookie';

import { AuthProvider } from 'AuthContext'; 

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

import jwt_decode from 'jwt-decode';

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  
  
  
  const isAuthenticated = () => {
  // Get token from cookie
  //const token = Cookies.get('jwtToken');
  // Get token from local
  const token = localStorage.getItem('jwtToken');

    if (!token) return false;
  
    try {
      const decodedToken = jwt_decode(token);
      const currentTime = Date.now() / 1000; // convert to Unix timestamp
      return decodedToken.exp > currentTime;
    } catch (error) {
      return false;
    }
  };
  



  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  
  const getRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }
    


    if (route.route) {
      if (route.key === "monitor" || route.key === "dashboard"  || route.key === "profile" || route.key === "dns" || route.key === "ai" || route.key === "firmendb"){
        return (
          <Route
            exact
            path={route.route}
            key={route.key}
            element={
              isAuthenticated() ? (
                route.component
              ) : (
                <SignIn />
              )

            }
          />
        );
      } 
      
      return <Route exact path={route.route} element={route.component} key={route.key} />;
      
    }


    return null;
  });

  
  const configsButton = (
    <MDBox>
    </MDBox>
  );

  return (
    isAuthenticated() ? (
    <AuthProvider>
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Suspense fallback={<div>Loading...</div>}>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Console Zebra360"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </Suspense>
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </Suspense>
    </ThemeProvider>
    </AuthProvider>
  ):(
    <ThemeProvider theme={darkMode ? themeDark : theme}>
    <CssBaseline />
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={
           isAuthenticated() ? (
            <Navigate to="/dashboard" />):(
            <Navigate to="/authentication/sign-in" />
          )
          } />
      </Routes>
    </Suspense>
  </ThemeProvider>
  )
  )
}