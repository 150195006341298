/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

import axios from 'axios';
// react-router-dom components
import { Link } from "react-router-dom";
import CryptoJS from 'crypto-js';

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import './style.css';
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.webp";

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isAlertVisible, setAlertVisibility] = useState(false);
  const LOGIN_SECRET = 'Zebra360.de';
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setAlertVisibility(false); 
      // encypt the plain password 
      const chipedPassword = CryptoJS.AES.encrypt(password, LOGIN_SECRET).toString();
      const response = await axios.post('https://console.zebra360.de/auth/login', { username, chipedPassword  });
  
      // Save JWT to cookie 
      //document.cookie = `jwtToken=${response.data.token}; Secure; HttpOnly`;
      // Save JWT to local
      localStorage.setItem('jwtToken', response.data.token);


      if (rememberMe) {
        // Only store the username, not the password
        localStorage.setItem('loginInfo', JSON.stringify({ username }));

      } else {
        localStorage.removeItem('loginInfo');
      }
      window.location.href = '/authentication/sign-in';
    } catch (error) {
      setAlertVisibility(true);
    }
  };

  useEffect(() => {
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    if (loginInfo) {
      setUsername(loginInfo.username);
      setPassword(loginInfo.password);
      setRememberMe(true);
    }
    setAlertVisibility(false); 
    
  }, []);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox className={`md-alert ${isAlertVisible ? 'md-alert-visible' : 'md-alert-hidden'}`}
                  variant="gradient"
                  bgColor="error"
                  
                  coloredShadow="error"
                  mx={0}
                  mt={0}
                  p={1}
                  mb={0}
                  textAlign="center"
                >
                  <MDTypography variant="button" fontWeight="regular" color="white">
                    Invalid username or password.
                  </MDTypography>
                  
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}> 
            <MDBox mb={2}>
              <MDInput type="username" label="Username" fullWidth onChange={(e) => setUsername(e.target.value)} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" fullWidth onChange={(e) => setPassword(e.target.value)} autoComplete="off" />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe}  onChange={() => setRememberMe(!rememberMe)} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={() => setRememberMe(!rememberMe)}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Login;
